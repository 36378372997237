@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  margin: 0;
  font-family: Calibri;

  color: #495c4f;
  scroll-behavior: smooth;

  /* border: 1px solid black; */
  /* line-height: 1.5; */
  box-sizing: border-box;
  text-align: justify;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: #8badf4;
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 5px;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

body {
  /* background: linear-gradient(
    90deg,
    rgb(249, 251, 249) 0%,
    rgb(253, 252, 252) 50%,
    rgb(255, 255, 255) 100%
  ); */
  background: #ffffff;
}
.social::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 90px;
  top: 50;
  left: 10;
  background-color: blue;
}
.brand-list {
  list-style-type: disc;
}

.badge-base {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

@media (min-width: 768px) {
  .text-lg {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }
}
